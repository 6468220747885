import React, { useState } from "react";
import logo from '../images/logo.png';
import shoppingcart from '../images/shopping-cart.png';
import up from '../images/up.png';
import down from '../images/down.png';
import userlogo from '../images/user-logo.png';
import petlogo from '../images/pet-logo.png';
import lost from '../images/lost.png';
import flag from '../images/flag.png';
import edit from '../images/edit.png';
import fb from '../images/fb.png';
import messenger from '../images/messenger.png';
import twitter from '../images/twitter.png';
import noahlogo from '../images/Noah.logo.png';
import avatar from '../images/avatar.png';
import copy from '../images/copy.png';
import { GrClose } from 'react-icons/gr';
import DataTable from 'react-data-table-component';


function copyTextToClipboard(text) {
  const textField = document.getElementById('copy-text');
  // textField.value = text;
  // document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
}

const Dashboard = () => {
  const [isInviteOpen, setInviteOpen] = useState(false);
  const openInviteModal = () => {
    // console.log("open modal");
    setInviteOpen(true);
  };
  const closeInviteModal = () => {
    // console.log("close modal");
    setInviteOpen(false);
  };
  const [isUserOpen, setUserOpen] = useState(false);
  const openUserInfo = () => {
    setUserOpen(true);
  };
  const closeUserInfo = () => {
    setUserOpen(false);
  };
  const columns = [
    {
      name: 'First Name',
      cell: (row) =>
      (
        <div className="flex gap-1.5">
          <img src={row.image} alt={`${row.firstName}`} onClick={openUserInfo} />
          <span className="text-sm">{row.firstName}</span>
        </div>

      ),
    },
    {
      name: 'Last Name',
      selector: row => row.lastName,
    },
    {
      name: 'Email/ID',
      selector: row => row.emailid,
    },
    {
      name: 'Mobile',
      selector: row => row.mobile,
    },
    {
      name: 'Last Active',
      selector: row => row.lastactive,
    },
    {
      name: 'Device',
      selector: row => row.device,
    },
    {
      name: 'Pets',
      selector: row => row.pets,
    },
    {
      name: 'Engagement',
      selector: row => row.engagement,
    },   
    {
      name: 'Posts',
      selector: row => row.posts,
    },
    {
      name: 'Status',
      selector: row => row.status,
    },
    {
      name: 'Verified',
      selector: row => row.verified,
    },
  ];
  const data = [
    {
      firstName: 'Beetlejuice',
      lastName: 'Jorge',
      image: avatar,
      emailid: 'pater@paterson.com',
      mobile: '(956) 123-1234',
      lastactive: '15 min ago',
      device: 'iphone OS SAFARI',
      pets: 'flag',
      engagement: '23 ^',
      posts: '5 + ^',
      status: 'Active',
    },
    {
      firstName: 'Harry',
      lastName: 'Porter',
      image: avatar,
      emailid: 'pater@paterson.com',
      mobile: '(956) 123-1234',
      lastactive: '15 min ago',
      device: 'iphone OS SAFARI',
      pets: 'flag',
      engagement: '23 ^',
      posts: '5 + ^',
      status: 'Active',
    },
    {
      firstName: 'Beetlejuice',
      lastName: 'Jorge',
      image: avatar,
      emailid: 'pater@paterson.com',
      mobile: '(956) 123-1234',
      lastactive: '15 min ago',
      device: 'iphone OS SAFARI',
      pets: 'flag',
      engagement: '23 ^',
      posts: '5 + ^',
      status: 'Active',
    },
    {
      firstName: 'Harry',
      lastName: 'Porter',
      image: avatar,
      emailid: 'pater@paterson.com',
      mobile: '(956) 123-1234',
      lastactive: '15 min ago',
      device: 'iphone OS SAFARI',
      pets: 'flag',
      engagement: '23 ^',
      posts: '5 + ^',
      status: 'Active',
    },
    {
      firstName: 'Beetlejuice',
      lastName: 'Jorge',
      image: avatar,
      emailid: 'pater@paterson.com',
      mobile: '(956) 123-1234',
      lastactive: '15 min ago',
      device: 'iphone OS SAFARI',
      pets: 'flag',
      engagement: '23 ^',
      posts: '5 + ^',
      status: 'Active',
    },
    {
      firstName: 'Harry',
      lastName: 'Porter',
      image: avatar,
      emailid: 'pater@paterson.com',
      mobile: '(956) 123-1234',
      lastactive: '15 min ago',
      device: 'iphone OS SAFARI',
      pets: 'flag',
      engagement: '23 ^',
      posts: '5 + ^',
      status: 'Active',
    },
    {
      firstName: 'Beetlejuice',
      lastName: 'Jorge',
      image: avatar,
      emailid: 'pater@paterson.com',
      mobile: '(956) 123-1234',
      lastactive: '15 min ago',
      device: 'iphone OS SAFARI',
      pets: 'flag',
      engagement: '23 ^',
      posts: '5 + ^',
      status: 'Active',
    },
    {
      firstName: 'Harry',
      lastName: 'Porter',
      image: avatar,
      emailid: 'pater@paterson.com',
      mobile: '(956) 123-1234',
      lastactive: '15 min ago',
      device: 'iphone OS SAFARI',
      pets: 'flag',
      engagement: '23 ^',
      posts: '5 + ^',
      status: 'Active',
    },
    {
      firstName: 'Beetlejuice',
      lastName: 'Jorge',
      image: avatar,
      emailid: 'pater@paterson.com',
      mobile: '(956) 123-1234',
      lastactive: '15 min ago',
      device: 'iphone OS SAFARI',
      pets: 'flag',
      engagement: '23 ^',
      posts: '5 + ^',
      status: 'Active',
    },
    {
      firstName: 'Harry',
      lastName: 'Porter',
      image: avatar,
      emailid: 'pater@paterson.com',
      mobile: '(956) 123-1234',
      lastactive: '15 min ago',
      device: 'iphone OS SAFARI',
      pets: 'flag',
      engagement: '23 ^',
      posts: '5 + ^',
      status: 'Active',
    },
  ];
  return (
    <>
      <div className="flex h-screen">
        <div className="bg-F1F2ED text-white w-4/5">
          <div className="flex items-center justify-center border h-16 gap-4">
            <img src={logo} alt="Logo" className="h-9 w-9 bg-white" />
            <div className="sideheader">
              <span className="text-base text-black font-black leading-4">Noah’s Ark Pet Connect</span>
              <p className="text-sm text-slate-600">Application Configuration Panel</p>
            </div>
          </div>
          <div className="py-2">
            <h2 className="text-sm font-black text-slate-500 leading-4 p-3 text-start">Connect, Rescue, Care</h2>
            <ul className="pl-8 space-y-4">
              <li className="flex items-center">
                <span className="rounded-full bg-blue-500 mr-2 w-10 h-10"></span>
                <span className="text-blue-700">Users</span>
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-lime-400 mr-2 w-10 h-10"></span>
                <span className="text-lime-700">Pets</span>
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-orange-500 mr-2 w-10 h-10"></span>
                <span className="text-orange-700">Pet Matches</span>
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-purple-500 mr-2 w-10 h-10"></span>
                <span className="text-purple-700">Lost Pets</span>
              </li>
            </ul>
          </div>
          <hr className='my-2 '></hr>

          <div className="py-2 text-black">
            <h2 className="text-sm font-black text-slate-500 leading-4 p-3 text-start">Content Management</h2>
            <ul className="pl-8 space-y-4">
              <li className="flex items-center">
                <span className="rounded-full bg-sky-100 mr-2 w-10 h-10"></span>
                <span className="text-blue-700">Users</span>
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-lime-100 mr-2 w-10 h-10"></span>
                <span className="text-lime-700">Pets</span>
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-orange-100 mr-2 w-10 h-10"></span>
                <span className="text-orange-700">Pet Matches</span>
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-purple-100 mr-2 w-10 h-10"></span>
                <span className="text-purple-700">Lost Pets</span>
              </li>
            </ul>
          </div>
          <hr className='my-2'></hr>

          <div className="py-2 text-black">
            <h2 className="text-sm font-black text-slate-500 leading-4 p-3 text-start">No Color</h2>
            <ul className="pl-8 space-y-4">
              <li className="flex items-center">
                <span className="rounded-full bg-gray-200 mr-2 w-10 h-10"></span>
                Users
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-gray-200 mr-2 w-10 h-10"></span>
                Pets
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-gray-200 mr-2 w-10 h-10"></span>
                Pet Matches
              </li>
              <li className="flex items-center">
                <img src={shoppingcart} alt="shopping-cart" className="mr-2 w-9 h-9 bg-gray-200 rounded-full p-2" />
                Store Settings
              </li>
            </ul>
          </div>
          <hr className='my-2'></hr>
          <div class="text-start text-blue-500 pl-5">
            <div> <p class="text-base">Terms of Services</p> </div>
            <div> <p class="text-base pt-2">Privacy Policy</p> </div>
          </div>
        </div>

        <div className="flex-grow">
          <section className="grid flex-col w-full p-5">
            <h1 className="flex ">User Dashboard</h1>
          </section>

          <section className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-6 w-4/5 p-5">
            <div class="flex items-center border border-transparent rounded-3xl p-5 bg-white">
              <div className="w-full">
                <div class="font-black leading-4 text-xs">
                  <img src={userlogo} alt="Icon" class="inline h-4 w-4 mr-1" /> App Users
                </div>
                <div class="text-2xl font-bold text-gray-600">2,765</div>
                <div class="text-xs font-bold text-emerald-400">
                  <img src={up} alt="Icon" class="inline h-3 w-3 mr-2" /> 10%
                </div>
              </div>
            </div>

            <div class="flex items-center border border-transparent rounded-3xl p-5 bg-white">
              <div className="w-full">
                <div class="font-black leading-4 text-xs">
                  <img src={petlogo} alt="Icon" class="inline h-4 w-4 mr-1" /> Pet Finder
                </div>
                <div class="text-2xl font-bold text-gray-600">253</div>
                <div class="text-xs font-bold text-orange-500">
                  <img src={down} alt="Icon" class="inline h-3 w-3 mr-2" /> 10%
                </div>
              </div>
            </div>

            <div class="flex items-center border border-transparent rounded-3xl p-5 bg-white">
              <div className="w-full">
                <div class="font-black leading-4 text-xs">
                  <img src={lost} alt="Icon" class="inline h-4 w-4 mr-1" /> Lost Pets
                </div>
                <div class="text-2xl font-bold text-gray-600">253</div>
                <div class="text-xs font-bold text-emerald-400">
                  <img src={up} alt="Icon" class="inline h-3 w-3 mr-2" /> 10%
                </div>
              </div>
            </div>

            <div class="flex items-center border border-transparent rounded-3xl p-5 bg-white">
              <div className="w-full">
                <div class="font-black leading-4 text-xs">
                  <img src={flag} alt="Icon" class="inline h-5 w-5 mr-1" /> App Users
                </div>
                <div class="text-2xl font-bold text-gray-600">253</div>
                <div class="text-xs font-bold text-orange-500">
                  <img src={down} alt="Icon" class="inline h-3 w-3 mr-2" /> 10%
                </div>
              </div>
            </div>

            <div class="flex items-center border border-transparent rounded-3xl p-5 bg-white">
              <div className="w-full">
                <div class="font-black leading-4 text-xs">
                  <img src={lost} alt="Icon" class="inline h-4 w-4 mr-1" /> App Users
                </div>
                <div class="text-2xl font-bold text-gray-600">253</div>
                <div class="text-xs font-bold text-orange-500">
                  <img src={down} alt="Icon" class="inline h-3 w-3 mr-2" /> 10%
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-4">
              <button className="border border-1 border-primary auth-btn bg-primary w-24 text-white p-3 text-xs font-semibold rounded-3xl mt-1 hover:bg-white hover:text-primary transition-colors transition-ease-in-out duration-500 w-fit" style={{ lineHeight: "6px" }}>Create User</button>
              <button className="border border-1 border-primary auth-btn bg-primary w-24 text-white p-3 text-xs font-semibold rounded-3xl mt-1 hover:bg-white hover:text-primary transition-colors transition-ease-in-out duration-500 w-fit" style={{ lineHeight: "6px" }} onClick={openInviteModal}>Invite Users</button>
            </div>

          </section>

          <section className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-6 w-4/5 pl-10">
            <div className="flex gap-x-14">
              <div className="reltive w-full">
                <label className="bg-gray-100 relative top-2 px-3 text-xs" for="Name">Name</label>
                <input type="text" name="Name" className="w-32 h-7 px-4 py-2 border border-black rounded-2xl text-sm" placeholder="" />
              </div>
              <div className="reltive w-full">
                <label className="bg-gray-100 relative top-2 px-3 text-xs" for="Name">Email</label>
                <input type="text" name="Name" className="w-32 h-7 px-4 py-2 border border-black rounded-2xl text-sm" placeholder="" />
              </div>
              <div className="reltive w-full">
                <label className="bg-gray-100 relative top-2 px-3 text-xs" for="Name">Mobile</label>
                <input type="text" name="Name" className="w-32 h-7 px-4 py-2 border border-black rounded-2xl text-sm" placeholder="" />
              </div>
              <div className="reltive w-full">
                <label className="bg-gray-100 relative top-2 px-3 text-xs" for="Name">Role</label>
                <select className="slect-box w-32 h-7 px-2 border border-black rounded-2xl text-sm">
                  <option className="" value="1">1</option>
                  <option className="" value="2">2</option>
                </select>
                {/* <input type="text" name="Name" class="w-32 h-7 px-4 py-2 border border-black rounded-2xl text-sm" placeholder="" /> */}
              </div>
              <div className="reltive w-full">
                <label className="bg-gray-100 relative top-2 px-3 text-xs" for="Name">Pet Owner</label>
                <select className="slect-box w-32 h-7 px-2 border border-black rounded-2xl text-sm">
                  <option className="" value="1">1</option>
                  <option className="" value="2">2</option>
                </select>
              </div>
              <div className="reltive w-full">
                <label className="bg-gray-100 relative top-2 px-3 text-xs" for="Name">Status</label>
                <select className="slect-box w-32 h-7 px-2 border border-black rounded-2xl text-sm">
                  <option className="" value="1">1</option>
                  <option className="" value="2">2</option>
                </select>
              </div>
            </div>
          </section>
          <section className="table-section w-full pl-9 mt-9">
            <DataTable
              columns={columns}
              data={data}
            />
          </section>

          {/* <!-- Invite modal --> */}
            <div
              data-te-modal-init
              className={`fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-backdrop  ${isInviteOpen ? "block" : "hidden"}`} >
              <div
                data-te-modal-dialog-ref
                className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[689px]">
                <div
                  className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                  <div
                    className="flex flex-shrink-0 items-center justify-between rounded-t-md p-5">
                    {/* <!--Modal title--> */}
                    <div className="flex w-full justify-center">
                    <h5
                      className="text-lg font-bold leading-normal text-neutral-800 dark:text-neutral-200  "
                      id="exampleModalScrollableLabel text-black">
                      GIVE $20, GET $20
                    </h5>
                    </div>
                    {/* <!--Close button--> */}
                    <div>
                    <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={closeInviteModal}>
                      <GrClose />
                      <span className="sr-only">Close modal</span>
                    </button>
                    </div>
                  </div>
                  <div>
                  <p className="text-base text-blue-950 font-semibold p-5">Tell your friend to enter your code at the checkout:</p>
                  </div>
                  <div>
                    <div className="flex justify-center p-5">
                      <h2 className="p-2 border-dashed border-2 border-blue-950 text-xl font-bold rounded-md w-96 text-blue-950">Mariana Janie</h2>
                    </div>
                      <div className="edit-text flex justify-center">
                        <p className="text-sm font-semibold p-3 text-blue-950">
                          <img src={edit} alt="Edit" style={{ display: "inline-block", verticalAlign: "middle", marginRight: "5px" }} />
                          Edit Recipient name
                        </p>
                    </div>
                  <div className="p-3">
                    <button className="w-[528px] border border-1 border-primary auth-btn bg-primary w-24 text-white p-3 text-xs font-semibold rounded-md mt-2 hover:bg-white hover:text-primary transition-colors transition-ease-in-out duration-500 p-5" style={{ lineHeight: "6px" }}>Share by email</button>
                  </div>
                <div className="share-via p-5">
                    <div className="text">
                      <p className="text-base font-bold text-blue-950">Share via</p>
                    </div>
                  <div className="flex justify-center items-center p-4 gap-16">
                    <img src={fb} alt="facebook"/>
                    <img src={messenger} alt="messenger"/>
                    <img src={twitter} alt="twitter"/>
                  </div>
                </div>
                <div className="app-logo flex justify-center pb-3">
                 <img src={noahlogo} alt="Logo" />
                </div>
                <div className="relative flex flex-col justify-center">
                  <div className="copy-text">
                    <p className="flex justify-center items-center text-center p-5 text-base font-normal text-blue-950">
                      Or copy your personal link
                    </p>
                  </div>                 
                  <div className="flex relative w-[528px] m-auto">
                    <input type="text" name="Name" id="copy-text" className="w-full h-7 p-6 border border-black text-base" value="noahsark-doglover1990" />
                      <button className="absolute right-0 top-0" onClick={() => copyTextToClipboard('noahsark-doglover1990')}>
                        <img src={copy} alt="PNG" />
                      </button>
                      <p className="flex justify-center items-center text-center p-5 text-base font-normal text-blue-950">
                      Successfully copied
                    </p>
                  </div>
                  </div>
              </div>
                  {/* <!--Modal body--> */}
                  <div className="relative p-4 mt-8">
                     <p className="text-blue-950 font-semibold">Terms & Conditions</p>
                  </div>

                  {/* <!--Modal footer--> */}
                </div>
              </div>
            </div>
           {/* <!-- Invite modal end--> */}
            {/* <!-- user info modal --> */}
            <div
              data-te-modal-init
              className={`fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-backdrop  ${isUserOpen ? "block" : "hidden"}`}
            >
              <div
                data-te-modal-dialog-ref
                className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center transition-all duration-300 ease-in-out min-w-[689px] sm:mx-auto sm:mt-7 sm:min-h-[calc(100%-3.5rem)] sm:max-w-[689px]"
              >
                <div className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                  <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                    {/* <!--Modal title--> */}
                    <h5
                      className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                      id="exampleModalScrollableLabel text-black"
                    >
                      Modal title
                    </h5>
                    {/* <!--Close button--> */}
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={closeUserInfo}
                    >
                      <GrClose />
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* <!--Modal body--> */}
                  <div className="relative p-4">
                    <p>User info modal.</p>
                  </div>
                  {/* <!--Modal footer--> */}
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;





