import React from "react";
import appLogo from '../images/app_logo.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import otprightimg from '../images/otp_right_img.png';
const initialValues = {
    password: ''
};
const Otp = () => {
    return(
        <>
        <div className='login-wrapper h-screen w-full flex items-center justify-center bg-left-bottom bg-white p-80	'>
                <div className="contents md:flex justify-between relative items-center sm:border border-black rounded-2xl w-70 overflow-hidden">
                    <div className='lg:w-1/2 w-full py-2 md:py-8 px-5 md:px-20'>
                        <img src={appLogo} className="app-logo text-center mx-auto mb-10" data-cy="logo" alt="Logo" />
                        <p className="text-20 text-center md:text-left font-normal text-black mb-7">Please input the one time pass code (OTP) sent to your email / phone.</p>
                        <Formik initialValues={initialValues}>
                            <Form>
                                <div className='mb-7'>
                                    <label htmlFor="email" className='block text-blackLight text-left mb-2'>One Time Passcode</label>
                                    <Field type="email" id="email" name="email" placeholder="------" className='bg-input w-full rounded-md tracking-widest p-3 focus:outline-none' />
                                    <ErrorMessage className='text-primary' name="email" component="div" />
                                </div>
                                <div className='mb-7'>
                                    <div className='flex items-center'>
                                        <div className='contents w-full md:w-1/2 mb-2 md:mb-0 md:mr-2 text-left'>
                                            <label className="relative inline-flex items-center mb-0 cursor-pointer">
                                                <input type="checkbox" value="" className="sr-only peer" />
                                                <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[3.5px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-primary peer-checked:bg-primary"></div>
                                                <span className="ml-3 font-medium text-black">Remember device for 30 Days</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                              
                                <button type="submit" disabled='' className='border border-1 border-primary auth-btn bg-primary w-full font-bold text-white p-3 text-20 rounded-md mt-1 hover:bg-white hover:text-primary transition-colors transition-ease-in-out duration-500'>
                                    Sign in
                                </button>
                                <button type="submit" disabled='' className='border border-1 border-primary auth-btn bg-primary w-full font-bold text-white p-3 text-20 rounded-md mt-8 hover:bg-white hover:text-primary transition-colors transition-ease-in-out duration-500'>
                                Resend One Time Pass code (OTP) 
                                </button>
                                <hr className='my-8'></hr>
                                <div className='bottom-block text-center mt-5'>
                                    <span className='text-blackLight'>Don’t have an account yet?</span>
                                    <span>
                                        <button type='button' className='font-normal ml-2'><Link to="" className='hover:text-primary duration-300 text-linkBlue duration-300'>Sign up now</Link></button>
                                    </span>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                    <div className="lg:w-1/2 xl:block lg:block md:hidden sm:hidden hidden absolute right-0 top-0 bottom-0">
                        <img src={otprightimg} className="login_right_img h-full w-full object-cover" alt="otp_right_img" />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Otp;