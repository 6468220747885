import React, { useState } from "react";
import logo from '../images/logo.png';
import shoppingcart from '../images/shopping-cart.png';
import up from '../images/up.png';
import down from '../images/down.png';
import userlogo from '../images/user-logo.png';
import petlogo from '../images/pet-logo.png';
import lost from '../images/lost.png';
import flag from '../images/flag.png';
import edit from '../images/edit.png';
import fb from '../images/fb.png';
import messenger from '../images/messenger.png';
import twitter from '../images/twitter.png';
import noahlogo from '../images/Noah.logo.png';
import avatar from '../images/avatar.png';
import copy from '../images/copy.png';
import pet from '../images/pet.png';
import urimg from '../images/ur-img.png';
import iconimg from '../images/icon-img.png';
import popupimg from '../images/popup-img.png';
import popupimg2 from '../images/popup-img2.png';
import { GrClose } from 'react-icons/gr';
import DataTable from 'react-data-table-component';
import rabies from '../images/rabies.png';
import bordatella from '../images/bordatella.png';


function copyTextToClipboard(text) {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
}

const PetsDashboard = () => {
  const [isInviteOpen, setInviteOpen] = useState(false);
  const openInviteModal = () => {
    // console.log("open modal");
    setInviteOpen(true);
  };
  const closeInviteModal = () => {
    // console.log("close modal");
    setInviteOpen(false);
  };
  const [isUserOpen, setUserOpen] = useState(false);
  const openUserInfo = () => {
    setUserOpen(true);
  };
  const [UserOpen, setOpen] = useState(false);
  const UserInfo = () => {
    setUserOpen(true);
  };
  const closeUserInfo = () => {
    setUserOpen(false);
  };
  const columns = [
    {
      name: 'Pet Name',
      cell: (row) =>
      (
        <div className="flex gap-1.5">
          <img src={row.image} alt={`${row.petName}`} onClick={openUserInfo} />
          <span className="text-sm">{row.petName}</span>
        </div>
      ),
    },
    {
      name: 'Type',
      selector: row => row.type,
    },
    {
      name: 'Sub Type',
      selector: row => row.subtype,
    },
    {
      name: 'Age',
      selector: row => row.age,
    },
    {
      name: 'Birthdate',
      selector: row => row.birthdate,
    },
    {
      name: 'Size',
      selector: row => row.Size,
    },
    {
      name: 'Vaccines',
      cell: (row) =>
      (
        <div className="flex gap-1.5">
          <img src={row.image} alt={`${row.vaccines}`} onClick={openUserInfo} />
          <span className="text-sm">{row.vaccines}</span>
        </div>
      ),
    },
    {
      name: 'Weight',
      selector: row => row.weight,
    },   
    {
      name: 'Followers',
      selector: row => row.followers,
    },
    {
      name: 'Status',
      selector: row => row.status,
    },
    {
      name: 'Tag #',
      selector: row => row.tag,
    },

  ];
  const data = [
    {
      petName: 'Fluffy956',
      type: 'Dog',
      image: avatar,
      subtype: 'French Poodle',
      age: '1 yr 3 m',
      birthdate: '04/20/2022',
      Size: 'Small',
      weight: '15 lbs',
      followers: '54',
      status: 'Active',
      tag: '48123',
    },
    {
        petName: 'Fluffy956',
        type: 'Dog',
        image: avatar,
        subtype: 'French Poodle',
        age: '1 yr 3 m',
        birthdate: '04/20/2022',
        Size: 'Small',
        weight: '15 lbs',
        followers: '54',
        status: 'Active',
        tag: '48123',
      },
      {
        petName: 'Fluffy956',
        type: 'Dog',
        image: avatar,
        subtype: 'French Poodle',
        age: '1 yr 3 m',
        birthdate: '04/20/2022',
        Size: 'Small',
        weight: '15 lbs',
        followers: '54',
        status: 'Active',
        tag: '48123',
      },
      {
        petName: 'Fluffy956',
        type: 'Dog',
        image: avatar,
        subtype: 'French Poodle',
        age: '1 yr 3 m',
        birthdate: '04/20/2022',
        Size: 'Small',
        weight: '15 lbs',
        followers: '54',
        status: 'Active',
        tag: '48123',
      },{
        petName: 'Fluffy956',
        type: 'Dog',
        image: avatar,
        subtype: 'French Poodle',
        age: '1 yr 3 m',
        birthdate: '04/20/2022',
        Size: 'Small',
        weight: '15 lbs',
        followers: '54',
        status: 'Active',
        tag: '48123',
      },
      {
        petName: 'Fluffy956',
        type: 'Dog',
        image: avatar,
        subtype: 'French Poodle',
        age: '1 yr 3 m',
        birthdate: '04/20/2022',
        Size: 'Small',
        weight: '15 lbs',
        followers: '54',
        status: 'Active',
        tag: '48123',
      },
      
  ];
  return (
    <>
      <div className="flex h-screen">
        <div className="bg-F1F2ED text-white w-4/5">
          <div className="flex items-center justify-center border h-16 gap-4">
            <img src={logo} alt="Logo" className="h-9 w-9 bg-white" />
            <div className="sideheader">
              <span className="text-base text-black font-black leading-4">Noah’s Ark Pet Connect</span>
              <p className="text-sm text-slate-600">Application Configuration Panel</p>
            </div>
          </div>
          <div className="py-2">
            <h2 className="text-sm font-black text-slate-500 leading-4 p-3 text-start">Connect, Rescue, Care</h2>
            <ul className="pl-8 space-y-4">
              <li className="flex items-center">
                <span className="rounded-full bg-blue-500 mr-2 w-10 h-10"></span>
                <span className="text-blue-700">Users</span>
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-lime-400 mr-2 w-10 h-10"></span>
                <span className="text-lime-700">Pets</span>
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-orange-500 mr-2 w-10 h-10"></span>
                <span className="text-orange-700">Pet Matches</span>
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-purple-500 mr-2 w-10 h-10"></span>
                <span className="text-purple-700">Lost Pets</span>
              </li>
            </ul>
          </div>
          <hr className='my-2 '></hr>

          <div className="py-2 text-black">
            <h2 className="text-sm font-black text-slate-500 leading-4 p-3 text-start">Content Management</h2>
            <ul className="pl-8 space-y-4">
              <li className="flex items-center">
                <span className="rounded-full bg-sky-100 mr-2 w-10 h-10"></span>
                <span className="text-blue-700">Users</span>
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-lime-100 mr-2 w-10 h-10"></span>
                <span className="text-lime-700">Pets</span>
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-orange-100 mr-2 w-10 h-10"></span>
                <span className="text-orange-700">Pet Matches</span>
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-purple-100 mr-2 w-10 h-10"></span>
                <span className="text-purple-700">Lost Pets</span>
              </li>
            </ul>
          </div>
          <hr className='my-2'></hr>

          <div className="py-2 text-black">
            <h2 className="text-sm font-black text-slate-500 leading-4 p-3 text-start">No Color</h2>
            <ul className="pl-8 space-y-4">
              <li className="flex items-center">
                <span className="rounded-full bg-gray-200 mr-2 w-10 h-10"></span>
                Users
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-gray-200 mr-2 w-10 h-10"></span>
                Pets
              </li>
              <li className="flex items-center">
                <span className="rounded-full bg-gray-200 mr-2 w-10 h-10"></span>
                Pet Matches
              </li>
              <li className="flex items-center">
                <img src={shoppingcart} alt="shopping-cart" className="mr-2 w-9 h-9 bg-gray-200 rounded-full p-2" />
                Store Settings
              </li>
            </ul>
          </div>
          <hr className='my-2'></hr>
          <div class="text-start text-blue-500 pl-5">
            <div> <p class="text-base">Terms of Services</p> </div>
            <div> <p class="text-base pt-2">Privacy Policy</p> </div>
          </div>
        </div>

        <div className="flex-grow">
          <section className="grid flex-col w-full p-5">
            <h1 className="flex ">Pets Dashboard</h1>
          </section>

          <section className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-6 w-4/5 p-5">
            <div class="flex items-center border border-transparent rounded-3xl p-5 bg-white">
              <div className="w-full">
                <div class="font-black leading-4 text-xs">
                  <img src={userlogo} alt="Icon" class="inline h-4 w-4 mr-1" /> App Users
                </div>
                <div class="text-2xl font-bold text-gray-600">2,765</div>
                <div class="text-xs font-bold text-emerald-400">
                  <img src={up} alt="Icon" class="inline h-3 w-3 mr-2" /> 10%
                </div>
              </div>
            </div>

            <div class="flex items-center border border-transparent rounded-3xl p-5 bg-white">
              <div className="w-full">
                <div class="font-black leading-4 text-xs">
                  <img src={petlogo} alt="Icon" class="inline h-4 w-4 mr-1" /> Pet Finder
                </div>
                <div class="text-2xl font-bold text-gray-600">253</div>
                <div class="text-xs font-bold text-orange-500">
                  <img src={down} alt="Icon" class="inline h-3 w-3 mr-2" /> 10%
                </div>
              </div>
            </div>

            <div class="flex items-center border border-transparent rounded-3xl p-5 bg-white">
              <div className="w-full">
                <div class="font-black leading-4 text-xs">
                  <img src={lost} alt="Icon" class="inline h-4 w-4 mr-1" /> Lost Pets
                </div>
                <div class="text-2xl font-bold text-gray-600">896</div>
                <div class="text-xs font-bold text-emerald-400">
                  <img src={up} alt="Icon" class="inline h-3 w-3 mr-2" /> 10%
                </div>
              </div>
            </div>

            <div class="flex items-center border border-transparent rounded-3xl p-5 bg-white">
              <div className="w-full">
                <div class="font-black leading-4 text-xs">
                  <img src={flag} alt="Icon" class="inline h-5 w-5 mr-1" /> App Users
                </div>
                <div class="text-2xl font-bold text-gray-600">253</div>
                <div class="text-xs font-bold text-orange-500">
                  <img src={down} alt="Icon" class="inline h-3 w-3 mr-2" /> 10%
                </div>
              </div>
            </div>

            <div class="flex items-center border border-transparent rounded-3xl p-5 bg-white">
              <div className="w-full">
                <div class="font-black leading-4 text-xs">
                  <img src={lost} alt="Icon" class="inline h-4 w-4 mr-1" /> App Users
                </div>
                <div class="text-2xl font-bold text-gray-600">253</div>
                <div class="text-xs font-bold text-orange-500">
                  <img src={down} alt="Icon" class="inline h-3 w-3 mr-2" /> 10%
                </div>
              </div>
            </div>
            <div className="flex mt-4 gap-2">
                <div className="flex flex-col gap-2">
                    <button className="border border-1 border-primary auth-btn bg-primary w-32 text-white p-3 text-xs font-semibold rounded-md mt-1 hover:bg-white hover:text-primary transition-colors transition-ease-in-out duration-500 shadow-lg shadow-slate-700" style={{ lineHeight: "6px" }}>Enroll Pet</button>
                    <button className="border border-1 border-primary auth-btn bg-primary w-32 text-white p-3 text-xs font-semibold rounded-md mt-1 hover:bg-white hover:text-primary transition-colors transition-ease-in-out duration-500 shadow-lg shadow-slate-700" style={{ lineHeight: "6px" }} onClick={openInviteModal}>Import Pet (s)</button>
                </div>
                <div>
                    <button className="border border-1 border-primary auth-btn bg-primary w-32 text-white p-3 text-xs font-semibold rounded-md mt-1 hover:bg-white hover:text-primary transition-colors transition-ease-in-out duration-500 shadow-lg shadow-slate-700" style={{ lineHeight: "6px" }} onClick={openInviteModal}>View Pet Map</button>
                </div>
            </div>
          </section>

          <section className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-6 w-4/5 pl-10">
            <div className="flex gap-x-14">
              <div className="reltive w-full">
                <label className="bg-gray-100 relative top-2 px-3 text-xs" for="Name">Pet Name</label>
                <input type="text" name="Name" className="w-32 h-7 px-4 py-2 border border-black rounded-md text-sm shadow-lg shadow-gray-300" placeholder="Fluffy" />
              </div>
              <div className="reltive w-full">
                <label className="bg-gray-100 relative top-2 px-3 text-xs" for="Name">Pet Type</label>
                <select className="slect-box w-32 h-7 px-2 border border-black rounded-md text-sm text-slate-400 shadow-lg shadow-gray-300">
                  <option className="" value="1">Dog</option>
                  <option className="" value="2">Cat</option>
                </select>
              </div>
              <div className="reltive w-full">
                <label className="bg-gray-100 relative top-2 px-3 text-xs" for="Name">Sub Type</label>
                <select className="slect-box w-32 h-7 px-2 border border-black rounded-md text-sm text-slate-400 shadow-lg shadow-gray-300">
                  <option className="" value="1">1</option>
                  <option className="" value="2">2</option>
                </select>
              </div>
              <div className="reltive w-full">
                <label className="bg-gray-100 relative top-2 px-3 text-xs" for="Name">Size</label>
                <select className="slect-box w-32 h-7 px-2 border border-black rounded-md text-sm text-slate-400 shadow-lg shadow-gray-300">
                  <option className="" value="1">1</option>
                  <option className="" value="2">2</option>
                </select>
                {/* <input type="text" name="Name" class="w-32 h-7 px-4 py-2 border border-black rounded-2xl text-sm" placeholder="" /> */}
              </div>
              <div className="reltive w-full">
                <label className="bg-gray-100 relative top-2 px-3 text-xs" for="Name">Status</label>
                <select className="slect-box w-32 h-7 px-2 border border-black rounded-md text-sm text-slate-400 shadow-lg shadow-gray-300">
                <option value="" disabled selected hidden>Active</option>
                  <option className="" value="1">1</option>
                  <option className="" value="2">2</option>
                </select>
              </div>
              <div className="reltive w-full">
                <label className="bg-gray-100 relative top-2 px-3 text-xs" for="Name">Tag #</label>
                <select className="slect-box w-32 h-7 px-2 border border-black rounded-md text-sm text-slate-400 shadow-lg shadow-gray-300">
                  <option className="" value="1">1</option>
                  <option className="" value="2">2</option>
                </select>
              </div>
              <div className="reltive w-full">
                <label className="bg-gray-100 relative top-2 px-3 text-xs" for="Name">Tag Status</label>
                <select className="slect-box w-32 h-7 px-2 border border-black rounded-md text-sm text-slate-400 shadow-lg shadow-gray-300">
                  <option className="" value="1">1</option>
                  <option className="" value="2">2</option>
                </select>
              </div>
            </div>
          </section>
          <section className="table-section w-full pl-9 mt-9">
            <DataTable
              columns={columns}
              data={data}
            />
          </section>
            <div
              data-te-modal-init
              className={`fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-backdrop h-full  ${isUserOpen ? "block" : "hidden"}`}
            >
              <div
                data-te-modal-dialog-ref
                className="w-3/5 absolute -right-5 h-full"
              >
                <div className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600 h-full">
                  <div className="flex flex-row flex-shrink-0 items-start justify-start rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-5 dark:border-opacity-50 bg-gray-100 shadow-lg shadow-gray-300 gap-14">
                    {/* <!--Close button--> */}
                    <div className="flex justify-end">
                    <img src={pet} alt="pet"/>
                    </div>

                    <div className="flex flex-col p-6 gap-2">
                    <div className="flex gap-2">
                        <div className="text-xl	font-bold text-stone-400">
                          <h2>Pet Name:</h2>
                        </div>
                        <div className="text-xl	font-bold text-stone-400">
                          <h3> Fluffy956</h3>
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <div className="text-xl	font-bold text-green-600">
                          <h2>Age:</h2>
                        </div>
                        <div className="text-xl	font-bold text-stone-400 ">
                          <h3>2 ys 3 m</h3>
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <div className="text-xl	font-bold text-green-600">
                          <h2>Size:</h2>
                        </div>
                        <div className="text-xl	font-bold text-stone-400">
                          <h3>Medium High</h3>
                        </div>
                    </div>
                    </div>

                    <div className="flex flex-col p-6 gap-2">
                    <div className="flex gap-4">
                        <div className="text-xl	font-bold text-stone-400">
                          <h2>Status:</h2>
                        </div>
                        <div className="text-xl	font-normal">
                          <h3>Open to Breed</h3>
                        </div>
                    </div>
                    <div className="flex gap-6">
                        <div className="text-xl">
                          <h2 className="text-stone-400 font-bold">Pet Owner</h2>
                          <h2>4523</h2>
                        </div>
                        <div className="text-xl	font-bold text-stone-400 ">
                        <img src={urimg} alt="Ur" />
                        </div>
                    </div>
                    </div>

                    <div className="flex flex-col p-6">
                    <div className="flex gap-2">
                        <div className="text-xl	font-bold text-stone-400">
                          <h2>Mariana Janeson </h2>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <div className="text-xl text-stone-400">
                          <h2>Pet Owner,</h2>
                          <h3>Verified</h3>
                        </div>
                        <div>
                        <img src={iconimg} alt="Icon-img" />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="text-xl	font-bold text-stone-400">
                          <h2>PRIVACY ENABLED</h2>
                        </div>
                    </div>
                    </div>   
                    <div className="btn">
                    <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={closeUserInfo}>
                      <GrClose />
                      <span className="sr-only">Close modal</span>
                    </button>
                    </div>
                  </div>
                  {/* <!--Modal body--> */}
                  <div className="flex justify-start">
                  <div className="relative p-4 flex flex-row">
                    <div>
                    <h2 className="font-semibold text-lg">Vaccination</h2>
                    </div>
                    <div className="flex">
                    <a href="PetsDashboard" className="text-sky-500 underline">View More</a>
                    </div>
                  </div>
                  </div>
                  <div className="relative p-4 justify-start">
                    <h2 className="flex justify-start mb-5 font-normal text-xl">Rabies</h2>
                    <img src={rabies} alt="Rabies" />
                  </div>
                  <div className="relative p-4 justify-start">
                    <h2 className="flex justify-start mb-5 font-normal text-xl">Bordatella</h2>
                    <img src={bordatella} alt="Bordatella" />
                  </div>
                  {/* <!--Modal footer--> */}
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};
export default PetsDashboard;





