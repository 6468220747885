import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Otp from './pages/otp';
import PetsDashboard from './pages/pets-dashboard';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/PetsDashboard' element={<PetsDashboard />} />
          <Route path='/' element={<Login />}/>
          <Route path='/otp' element={<Otp/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
